<template>
	<v-container fluid>
    <default-modal
      v-if="showModal"
      :cancel-message="'Close'"
      :message="'LOGIN FAIL'"
      @close="showModal= false"
    />
	<v-form ref="form" v-model="valid" @submit.prevent="onSubmitLogin" style="width: 100%;">
	<div class="hr-login-wrap">
		<div class="hr-container">
			<div class="hr-logo-wrap">
				<h1 class="hr-logo">휴림네트웍스로고</h1>
			</div>
			<div class="hr-login-box-wrap">
				<div class="m-w-480">
					<div class="hr-box">
						<div class="hr-login-box">
							<h1 class="box-title">Welcome Back</h1>
							<div class="mg-20b">
								<input type="text" class="login-id" v-model="username" placeholder="ID를 입력해 주세요." required autocomplete="off">
							</div>
							<div class="mg-20b">
								<input type="password" class="login-password" v-model="password" placeholder="비밀번호를 입력해주세요." required autocomplete="off">
							</div>
							<div>
                <button class="login-btn" type="submit" > 로그인하기 </button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="hr-login-background">
			<div class="hr-login-background-inner">
				<div class="h-l-b-left-div"></div>
				<div class="h-l-b-right-div"></div>
			</div>
			<div class="hr-login-background-shape-div"></div>
		</div>
	</div>
	</v-form>
	</v-container>
</template>

<script>
import DefaultModal from "@/components/modals/default_modal.vue";

export default {
  components: {DefaultModal},
  name: 'SignIn',
  data() {
    return {
      showModal: false,
      valid: false,
      username: '',
      password: '',
    };
  },
  methods: {
    async onSubmitLogin() {
      try {
        const data = await this.$store.dispatch('users/logIn', {
          id: this.username,
          password: this.password,
        });

        if (data.status === 'success') {
          await this.$router.replace('/');
        } else {
          this.showModal= true;
        }
      } catch (err) {
        console.log(err);
        this.showModal= true;
      }
    }
  },
};
</script>
<style lang="css" scoped>
@import '~@/assets/style/login.css';
</style>

